.loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3c3c3c;
  transition: opacity 0.5s ease;
  pointer-events: none;
}
.loader .img {
  pointer-events: none;
  width: 250px;
}

#loader.finished {
  opacity: 0;
  pointer-events: none;
  display: none;
  visibility: hidden;
}
#loader.finished .img {
  display: none;
}
