.about {
  /* background-color: #3c3c3c; */
  position: relative;
  overflow: hidden;
}

.about > img {
  position: absolute;
  width: 260px;
  bottom: 0px;
  right: 0px;
  z-index: -1;
  /* opacity: 0.9; */
}

.about__container {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 80%;
  margin: auto;
  min-height: 50vh;
}

.about__container-heading {
  color: #28bbec;
  padding-bottom: 12px;
  margin-bottom: 30px;
  position: relative;
  letter-spacing: 4px;
  font-size: 2.3rem;
  font-weight: 850;
}
.about__container-heading::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50;
  width: 20%;
  height: 8px;
  background: #ebebec;
  border-radius: 10px;
}

.about__container-markus {
  font-weight: 700 !important;
  color: #3bb4d9;
}

.about__container-subheading {
  font-weight: 700;
  font-size: 1.4rem;
  color: rgb(14, 13, 13);
  color: #0a2540;
  margin-bottom: 25px;
}
.about__container-text {
  color: #425466;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

@media (max-width: 850px) {
  .about__container-heading {
    text-align: center;
  }
  .about__container-heading::before {
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    background: #3bb9e0;
  }
}

@media (max-width: 768px) {
  .about__container {
    width: 100%;
    padding: 2rem 1.2rem;
  }
  .about > img {
    opacity: 0.5;
  }

  .about__container-heading {
    font-size: 2rem;
  }
}

@media (max-width: 568px) {
  .about__container-subheading {
    font-size: 1.2rem;
  }
}
