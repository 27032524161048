.nav-mobile {
  display: none;
  color: #dbdada;
}

nav.nav-mobile {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  /* background: #33393b; */
  background: #313131;
}

nav.nav-mobile button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  pointer-events: all !important;
  /* z-index: 99999; */
}

nav.nav-mobile ul,
nav.nav-mobile > li {
  margin: 0;
  padding: 0;
}

nav.nav-mobile ul {
  /* padding: 25px; */
  position: absolute;
  top: 100px;
  /* width: 230px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

nav.nav-mobile li {
  list-style: none;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* nav.nav-mobile li > a {
  text-decoration: none;
  color: rgb(226, 226, 226);
  font-size: 1.7rem;
} */

/* nav.nav-mobile {
  border-radius: 5px;
  width: 100%;
  flex: 1;
} */
nav.nav-mobile li a {
  text-decoration: none;
  color: rgb(226, 226, 226);
  font-size: 1.5rem;
  padding: 1rem;
}

nav.nav-mobile li a.active {
  color: #3bb9e0;
}

nav.nav-mobile .icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

nav.nav-mobile .refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 938px) {
  .nav-mobile {
    display: block;
  }
}

@media (max-width: 600px) {
  nav.nav-mobile {
    width: 350px !important;
  }

  nav.nav-mobile ul {
    right: 0 !important;
  }

  .background {
    width: 350px !important;
  }
}
@media (max-width: 700px) {
  nav.nav-mobile ul {
    right: 20px;
  }
}
@media (max-width: 500px) {
  nav.nav-mobile {
    width: 100% !important;
  }
  nav.nav-mobile ul {
    right: 0;
  }

  .background {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  nav.nav-mobile button {
    top: 5px !important;
  }
}
