@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Raleway", sans-serif !important;
  /* overflow-x: hidden !important; */
}

/* .App {
  visibility: hidden;
  display: none;
  opacity: 0;
}
.App.show {
  display: block;
  visibility: visible;
  opacity: 1;
} */

.loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3c3c3c;
  transition: opacity 0.5s ease;
  pointer-events: none;
}
.loader .img {
  pointer-events: none;
  width: 250px;
}

#loader.finished {
  opacity: 0;
  pointer-events: none;
  display: none;
  visibility: hidden;
}
#loader.finished .img {
  display: none;
}

.home {
  background-color: #3c3c3c;
}

.home__container {
  width: 95%;
  height: 93vh;
  max-width: 1600px;
  margin: auto;
}

/* .carousel__item {
  background-color: #3c3c3c;
} */

.home__hero {
  display: grid;
  height: 90vh;
  padding: 0 3rem;
  grid-template-columns: 3fr 2fr;
  /* grid-template-rows: repeat(2, 1fr); */
  place-items: center;
}

.home__hero-hadings {
  text-align: left;
  /* grid-column: 1/2;
  grid-row: 1/3;
  align-self: center; */
}

.home__hero-image {
  /* grid-column: 2/3;
  grid-row: 1/3; */
  /* align-self: center; */
  /* align-self: flex-end; */
  pointer-events: none;
}

.home__image {
  pointer-events: none;
  margin-left: 5px;
}

.home__hero-hadings > h1 {
  color: #3bb9e0;
  text-transform: uppercase;
  font-weight: bolder !important;
  font-size: 1.7rem;
  margin-bottom: 10px;
  letter-spacing: 2px;
  /* line-height: 2rem; */
}
.home__hero-hadings > p {
  color: #fff;
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
  line-height: 3.5rem;
}

.home__subtext {
  color: #90d9f1 !important;
  font-size: 1.8rem !important;
  margin-bottom: 2rem !important;
  line-height: 2.3rem !important;
}

.home__hero-image .home__image {
  max-width: 95%;
  object-fit: contain;
}

.telecharger_btn {
  text-decoration: none !important;
  color: #fff !important;
  padding: 0.5rem 3rem;
  border: 1px solid white;
  border-radius: 9px;
  font-size: 1.3rem;
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
}

.telecharger_btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 9px;
  background: #2098d1 !important;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

.telecharger_btn:hover:before,
.telecharger_btn:focus:before,
.telecharger_btn:active:before {
  transform: scaleX(1);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.thumbs-wrapper {
  display: none;
}

.carousel.carousel-slider .control-arrow:hover {
  background: transparent !important;
}

/* Arrow */
.carousel.carousel-slider .control-arrow:before {
  margin: 0 10px;
  display: inline-block;
  border-top: 15px solid transparent !important;
  border-bottom: 15px solid transparent !important;
  content: "";
}

.carousel .control-prev.control-arrow:before {
  border-right: 15px solid #fff !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 15px solid #fff !important;
}

/* indicator */
.carousel-status {
  display: none;
}

.dot {
  width: 15px !important;
  height: 15px !important;
}

.carousel-control-next,
.carousel-control-prev {
  width: 5% !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 32px !important;
  height: 32px !important;
}

@media (max-width: 1100px) {
  .home__hero-hadings > p {
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 950px) {
  .home__hero-hadings > p {
    line-height: 3rem;
  }
}

@media (max-width: 768px) {
  .home__hero-hadings > h1 {
    font-size: 1.5rem;
  }
  .home__hero-hadings > p {
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .home__subtext {
    font-size: 1.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .home__hero {
    grid-template-columns: 1fr;
    padding: 0;
    position: relative;
    z-index: 3;
  }

  .home__hero-image {
    position: absolute;
    top: 55%;
    left: 70%;
    right: 0%;
    transform: translate(-50%, 10%);
    z-index: -1;
    opacity: 0.32;
  }

  .home__hero-image .home__image {
    width: 100%;
    object-fit: contain;
    transform: scale(2);
  }
}
@media (max-width: 583px) {
  .home__hero-hadings > h1 {
    font-size: 1.5rem;
  }
  .home__hero-hadings > p {
    font-weight: bold;
    font-size: 1.7rem;
    line-height: 2.7rem;
  }

  .home__subtext {
    font-size: 1.3rem !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 8% !important;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 25px !important;
    height: 25px !important;
  }
}

@media (max-width: 550px) {
  .home__hero-image {
    position: absolute;
    /* display: none; */
    top: 47%;
    right: 18%;
    transform: translate(0%, 80%);
    z-index: -1;
    opacity: 0.3;
  }

  .home__hero-image .home__image {
    width: 100%;
    object-fit: contain;
    transform: scale(6);
  }
  .home__hero-hadings > h1 {
    font-size: 1.3rem;
  }
  .home__hero-hadings > p {
    font-weight: bold;
    font-size: 1.8rem;
  }

  .home__subtext {
    font-size: 1.4rem !important;
  }

  .telecharger_btn {
    display: block;
    font-size: 1.2rem;
    padding-left: 0;
    padding-right: 0;
    width: 60%;
    text-align: center;
    margin: auto;
  }

  /* .home__hero-image {
    display: none;
  }

  .home__hero-image .home__image {
    display: none;
  } */
}
@media (max-width: 400px) {
  .telecharger_btn {
    font-size: 1.1rem;
    width: 90% !important;
  }
}
@media (max-width: 370px) {
  .home__hero-hadings > h1 {
    font-size: 1rem;
  }
  .home__hero-hadings > p {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1.5rem !important;
  }

  .home__subtext {
    font-size: 1.3rem !important;
    margin-bottom: 2rem !important;
  }
}
/* Carousel bootstrap style */

/* .carousel-control-next,
.carousel-control-prev {
  width: 5%;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 32px;
  height: 32px;
} */

/* .carousel {
  position: relative;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  width: 5%;
}
[role="button"] {
  cursor: pointer;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
    width: 40px ;
  height: 40px ;
  background: no-repeat 50%/100% 100%;
}


.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
} */

.fonctionnalites__wrapper {
  background-color: #3c3c3c;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgb(88, 88, 88);
}

.fonctionnalites__bgImage {
  position: absolute;
  width: 250px;
  bottom: 0;
}

.fonctionnalites {
  width: 95%;
  max-width: 1400px;
  /* height: 100vh; */
  padding: 3rem 0 3rem;
  margin: 0 auto 0;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.fonctionnalites__container {
  /* display: grid;
  grid-template-columns: 3fr 2fr;
  place-items: center; */

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* flex-direction: column; */

  /* height: 100%; */
  /* place-content: center; */
}

.fonctionnalites__content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fonctionnalites__heading {
  color: #fff;
  /* text-align: center; */
  white-space: pre-wrap;
  font-size: 2.3rem;
  font-weight: 600;
  margin: 0 0 0;
}

.fonctionnalites__heading:last-of-type {
  margin-bottom: 1rem;
}

.fonctionnalites__subHeading {
  color: #90d9f1;
  /* text-align: center; */
  white-space: pre-wrap;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 2rem;
}

.fonctionnalites__smartphone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 0.35 1;
}

.fonctionnalites__smartphone img {
  max-width: 60%;
  object-fit: contain;
}

.fonctionnalites__content-container {
  flex: 0.75 1;
}

.fonctionnalites__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fonctionnalites__nom {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  white-space: pre-wrap;
  color: #fff;
  margin-bottom: 1.2rem;
}

.fonctionnalites__icon {
  color: #2098d1 !important;
}

@media (max-width: 1000px) {
  .fonctionnalites__heading {
    font-size: 2rem;
  }

  .fonctionnalites__subHeading {
    font-size: 1.2rem;
  }
  .fonctionnalites__nom {
    font-size: 1.3rem;
  }

  .fonctionnalites__bgImage {
    opacity: 0.5;
  }
}

@media (max-width: 800px) {
  .fonctionnalites__container {
    grid-template-columns: 1fr;
    /* grid-template-rows: 1fr 1fr; */
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
  }
  .fonctionnalites__bgImage {
    display: none;
    visibility: hidden;
  }

  .fonctionnalites__content {
    align-items: center !important;
  }
  .fonctionnalites__content > div {
    align-items: flex-start !important;
  }
  .fonctionnalites__nom {
    flex-direction: row !important;
    text-align: left !important;
  }

  .fonctionnalites__icon {
    margin-left: 0 !important;
    margin-right: 1.5rem;
  }

  .fonctionnalites__heading {
    text-align: center;
  }

  .fonctionnalites__subHeading {
    text-align: center;
  }

  .fonctionnalites__smartphone img {
    /* display: none; */
    max-width: 25% !important;
    margin: 0 !important;
  }
}

@media (max-width: 668px) {
  .fonctionnalites {
    width: 100%;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

@media (max-width: 550px) {
  .fonctionnalites__heading {
    font-size: 1.8rem;
  }

  .fonctionnalites__subHeading {
    font-size: 1.1rem;
  }

  .fonctionnalites__nom {
    font-size: 1.1rem;
  }
}
@media (max-width: 500px) {
  .fonctionnalites__smartphone {
    /* display: none; */
    /* grid-row: 0/1; */
  }
  .fonctionnalites__content {
    /* grid-row: 1/2; */
  }
  .fonctionnalites__smartphone img {
    /* display: none; */
  }

  .fonctionnalites__heading {
    font-size: 1.4rem;
  }

  .fonctionnalites__smartphone img {
    max-width: 38% !important;
    object-fit: contain;
    justify-self: center;
    margin: 1rem auto 0 auto;
  }
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-bottom: 2px solid rgb(128, 128, 128);
  background: linear-gradient(to top, #383838, #525151, #525151) !important;
  /* border-bottom: 2px solid rgb(92, 91, 91); */
  /* background: #303030; */
  z-index: 99;
}
.navbar__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 5vh;
  padding: 6px 5px 8px 5px;
  width: 95%;
  max-width: 1300px;
  margin: auto;
}

.navbar__logo-container {
  /* width: 150px; */
}

.navbar__logo {
  width: 90px;
  object-fit: contain;
}

.navbar__links {
  display: flex;
  width: 60%;
  justify-content: space-between;
}
.navbar__links a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
}

.navbar__links a.active {
  color: #3bb9e0;
}

@media (max-width: 1250px) {
  .navbar__links {
    width: 70%;
  }
}

@media (max-width: 938px) {
  .navbar__links {
    display: none;
  }
}
@media (max-width: 600px) {
  .navbar__container {
    height: 40px;
  }

  .navbar__logo {
    width: 70px;
    object-fit: contain;
  }
}

.nav-mobile {
  display: none;
  color: #dbdada;
}

nav.nav-mobile {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  /* background: #33393b; */
  background: #313131;
}

nav.nav-mobile button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  pointer-events: all !important;
  /* z-index: 99999; */
}

nav.nav-mobile ul,
nav.nav-mobile > li {
  margin: 0;
  padding: 0;
}

nav.nav-mobile ul {
  /* padding: 25px; */
  position: absolute;
  top: 100px;
  /* width: 230px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

nav.nav-mobile li {
  list-style: none;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* nav.nav-mobile li > a {
  text-decoration: none;
  color: rgb(226, 226, 226);
  font-size: 1.7rem;
} */

/* nav.nav-mobile {
  border-radius: 5px;
  width: 100%;
  flex: 1;
} */
nav.nav-mobile li a {
  text-decoration: none;
  color: rgb(226, 226, 226);
  font-size: 1.5rem;
  padding: 1rem;
}

nav.nav-mobile li a.active {
  color: #3bb9e0;
}

nav.nav-mobile .icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

nav.nav-mobile .refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 938px) {
  .nav-mobile {
    display: block;
  }
}

@media (max-width: 600px) {
  nav.nav-mobile {
    width: 350px !important;
  }

  nav.nav-mobile ul {
    right: 0 !important;
  }

  .background {
    width: 350px !important;
  }
}
@media (max-width: 700px) {
  nav.nav-mobile ul {
    right: 20px;
  }
}
@media (max-width: 500px) {
  nav.nav-mobile {
    width: 100% !important;
  }
  nav.nav-mobile ul {
    right: 0;
  }

  .background {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  nav.nav-mobile button {
    top: 5px !important;
  }
}

form {
  max-width: 600px;
  background: white;
  text-align: left;
  /* padding: 10px 40px; */
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.submit {
  text-align: center !important;
  background: #35a7e9 !important;
}

.error {
  display: block;
  width: 100%;
  color: #ff0062;
  font-size: 0.8em;
  font-weight: bold;
}

.error:last-of-type {
  margin-bottom: 8px;
}

.contact {
  padding: 35px 0 35px;
  max-width: 1200px;
  margin: auto;
  /* min-height: 91vh; */
  display: flex;
  place-items: center;
}

.contact__container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
}

.contact__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.contact__header {
  text-align: center;
  /* max-width: 40%; */
}

.contact__heading {
  position: relative;
  padding-bottom: 12px;
  color: #3bb9e0;
  font-size: 2.3rem;
  font-weight: 850;
}

.contact__heading::before {
  margin: auto;
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 25%;
  height: 8px;
  background: #ebebec;
  border-radius: 10px;
}

.contact__text {
  margin: 1rem 0 0;
  color: #3bb9e0;
  font-weight: 500;
  text-align: center;
}

@media (max-width: 860px) {
  .contact__container {
    grid-template-columns: 1fr;
  }

  .contact-details {
    order: 1;
    border-top: 1px solid rgb(224, 221, 221);
    margin: 1rem;
    padding-top: 1.5rem;
  }

  .contact__form {
    order: 0;
  }

  .contact__form {
    align-items: center;
    padding: 1rem;
  }

  .contact__header {
    text-align: center;
    /* max-width: 40%; */
  }

  .contact__heading {
    font-size: 2rem;
  }

  .contact__heading::before {
    left: 50%;
    transform: translateX(-50%);
    width: 25%;
    background: #3bb9e0;
  }
}

.contact .MuiCardContent-root,
.contact .MuiTypography-root {
  font-family: "Raleway", sans-serif !important;
  font-size: 15px !important;
  font-weight: 500;
}

.contact-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.contact-details .MuiPaper-root:last-of-type {
  display: flex;
  justify-content: center;
}

@media (max-width: 1080px) {
  .contact-details .MuiPaper-root {
    width: 80%;
  }
}

@media (max-width: 900px) {
  .contact-details {
    align-items: flex-start;
    padding-left: 10px;
  }

  .contact-details .MuiPaper-root {
    width: 85%;
  }
}
@media (max-width: 860px) {
  .contact-details {
    padding-left: 0;
  }
  .contact-details .MuiPaper-root {
    width: 250px !important;
  }

  .contact-details {
    flex-wrap: wrap !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .contact-details .MuiCard-root {
    margin: 1rem !important;
  }
}

@media (max-width: 600px) {
  .contact-details {
    display: block !important;
    text-align: center;
  }

  .contact-details .MuiCard-root {
    width: 100% !important;
    margin: 1rem 0 !important;
  }

  .contact-details .MuiCard-root:last-of-type {
    /* display: none; */
  }

  .contact-details .MuiCard-root .MuiTypography-root {
    font-size: 12px;
  }
}

.footer {
  background: #353434;
}

.footer__container {
  max-width: 1600px;
  width: 90%;
  height: 100%;
  margin: auto;
  padding: 1.5rem 0 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  /* place-items: center; */
  /* place-content: center; */
}

.footer__a-propos-de-nous {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 0.5px solid rgb(70, 70, 70);
}

.footer__a-propos-de-nous .footer__a-propos-de-nous__image-container {
  max-width: 125px;
}

.footer__a-propos-de-nous .footer__a-propos-de-nous__image-container img {
  width: 100%;
  object-fit: contain;
}

.footer__a-propos-de-nous__content {
  color: rgb(175, 175, 175);
}

.footer__a-propos-de-nous__content__headings {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  /* color: #44aeeb; */
}

.footer__telechargement {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-right: 0.5px solid rgb(70, 70, 70);
}

.footer__telechargement > h1 {
  font-size: 1.1rem;
  color: rgb(226, 223, 223);
  position: relative;
  padding-bottom: 0.7rem;
}

.footer__telechargement > h1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20%;
  height: 5px;
  background: rgb(119, 113, 113);
  border-radius: 10px;
}

.footer__telechargement > a {
  color: rgb(226, 223, 223);
  padding: 10px 0 5px;
}
.footer__telechargement > a > img {
  width: 30px;
  margin-right: 10px;
}

.footer__contacts {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 5px;
}

.footer__contacts > h1 {
  font-size: 1.1rem;
  color: rgb(226, 223, 223);
  position: relative;
  padding-bottom: 0.7rem;
}

.footer__contacts > h1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20%;
  height: 5px;
  background: rgb(119, 113, 113);
  border-radius: 10px;
}

.footer__contacts-content > p,
.footer__contacts-content > a {
  margin: 3px 0 !important;
  padding: 0;
  font-size: 0.7rem;
}
.footer__contacts-content > a {
  display: block;
  text-decoration: none;
}

.footer__contacts-content {
  color: rgb(151, 150, 150);
}

.footer__contacts-content a {
  color: #ccc;
}

.footer__contacts-content i {
  margin-right: 10px;
}

.footer__suivez-nous {
  display: flex;
  align-self: center;
}

.footer__suivez-nous a {
  margin-right: 1rem;
}
.footer__suivez-nous a > i {
  color: #ccc;
}

.footer__contacts {
  border-right: 0.5px solid rgb(70, 70, 70);
}

.footer__legales {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.footer__legales > h1 {
  font-size: 1.1rem;
  color: rgb(226, 223, 223);
  position: relative;
  padding-bottom: 0.7rem;
}

.footer__legales > h1::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20%;
  height: 5px;
  background: rgb(119, 113, 113);
  border-radius: 10px;
}

.footer__legales > a {
  margin: 3px 0;
  padding: 0;
  font-size: 0.7rem;
  color: rgb(151, 150, 150);
}

@media (min-width: 1500px) {
  .footer__suivez-nous {
    padding-top: 15px;
  }
}

@media (max-width: 1200px) {
  .footer__container {
    width: 98%;
  }
}
@media (max-width: 1100px) {
  .footer__container {
    width: 98%;
  }
}

@media (max-width: 850px) {
  .footer__container {
    grid-template-columns: 1fr;
    width: 90%;
  }

  .footer__a-propos-de-nous {
    border-right: 0;
    border-bottom: 0.75px solid rgb(102, 100, 100);
  }
  .footer__contacts {
    border-right: 0;
    border-bottom: 0.75px solid rgb(102, 100, 100);
    padding: 25px 8px;
  }

  .footer__telechargement {
    border-right: 0;
    border-bottom: 0.75px solid rgb(102, 100, 100) !important;
    padding: 25px 8px;
  }
}

.footerMobile__a-propos-de-nous {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footerMobile__a-propos-de-nous__image-container {
  margin-bottom: 1rem;
  width: 150px;
}

.footerMobile__a-propos-de-nous__image-container img {
  width: 100%;
  object-fit: contain;
}

.footerMobile__telechargement,
.footerMobile__contacts {
  border-right: 0 !important;
  border-bottom: 0 !important;
  padding: 0 !important;
  width: 100%;
}

.footerMobile__contacts .footer__suivez-nous {
  padding-top: 5px;
  text-align: center;
}

.telecharger {
  background-color: #3c3c3c;
}

.telecharger-page {
  /* background: #f6f7fa; */
  /* padding: 1rem 1rem 2rem; */
  padding: 30px 10px 30px;
  color: #333;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  line-height: 22px;
  box-sizing: border-box;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.telecharger__headings {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.telecharger__headings h1 {
  padding-bottom: 10px;
  margin: 5px 0 16px;
}

.telecharger__headings > p {
  font-weight: 550;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-top: 2rem;
}
.telecharger__headings > p:last-child {
  margin-top: 1rem;
  color: #3bb9e0;
  text-transform: none;
}

.telecharger__headings > p > span {
  color: rgb(228, 225, 225);
}

.telecharger__headings > h4 {
  font-size: 1.3rem;
  font-weight: lighter !important;
  color: rgb(165, 165, 165);
}

.telecharger__container {
  width: 45vw;
  /* height: 100%; */
  display: -ms-grid;
  display: grid;
  overflow: visible;
  grid-auto-columns: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  place-items: center;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
}

a:active,
a:hover {
  outline: 0;
}
.telecharger__card {
  width: 100%;
  padding: 40px 20px;
  /* border: 0.7px solid #f6f7fa; */
  border-radius: 15px;
  background-color: rgb(61, 61, 61);
  transition: all 200ms ease;
  color: #333;
  text-decoration: none;
  max-width: 100%;
  display: inline-block;
  box-shadow: 0 6px 31px -2px rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.telecharger__card:hover {
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.4);
  transform: translate(0px, -10px);
}

.telecharger__card__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.telecharger__card__image-container {
  text-align: center;
  height: 200px;
  width: 100%;
  position: relative;
}

.telecharger__card__image-container img {
  display: inline-block;
  object-fit: contain;
  height: 300px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.telecharger__card__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.telecharger__card__content > h1 {
  font-size: 0.7rem;
  padding: 0.5rem 0.75rem;
  border-radius: 12px;
  color: rgb(226, 226, 226);
  display: flex;
  align-items: center;
  box-shadow: 0 6px 31px -2px rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  text-align: center;
}
.telecharger__card__content > h1.android {
  /* background-color: #0ceb7b; */
  /* color: rgb(10, 10, 10); */
}

.telecharger__card__content > h1 > i {
  margin-right: 10px;
}

.telecharger__card__content > h1.android > i {
  color: #3de21c !important;
}

.telecharger__card__content .telecharger__button img {
  max-width: 125px;
  height: 30px;
  object-fit: contain;
}

.telecharger__button {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  border-radius: 10px;
  padding: 10px 15px;
  background: transparent;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  color: rgb(226, 222, 222);
}

.telecharger__button:hover {
  cursor: pointer;
}

.telecharger__button span {
  margin: 0 10px;
}

@media (max-width: 1309px) {
  .telecharger__container {
    width: 60vw;
  }
}
@media (max-width: 990px) {
  .telecharger__container {
    width: 80vw;
  }
}

@media (max-width: 750px) {
  .telecharger__headings {
    text-align: center;
  }

  .telecharger__container {
    width: 90vw;
  }
}
@media (max-width: 660px) {
  .telecharger__container {
    width: 95vw;
  }
}

@media (max-width: 600px) {
  .telecharger__container {
    width: 70vw;
    grid-template-columns: 1fr;
    grid-row-gap: 100px;
    padding-top: 20px;
  }

  /* .telecharger {
    padding-top: 50px;
    justify-content: space-between;
  } */

  .telecharger__headings {
    margin-bottom: 70px;
  }

  .telecharger__headings > p:last-child {
    font-size: 1.3rem;
  }

  .telecharger__button {
    font-size: 15px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid rgb(117, 116, 116);
  }

  .telecharger__card {
    -webkit-backdrop-filter: unset;
            backdrop-filter: unset;
  }

  .telecharger__card__content > h1 {
    box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid rgb(117, 116, 116);
    -webkit-backdrop-filter: unset;
            backdrop-filter: unset;
  }
}

@media (max-width: 330px) {
  .telecharger__container {
    width: 80vw;
  }
}

.presentation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  /* max-width: 1300px; */
  /* min-height: 60vh; */
  padding: 2rem 1rem 2rem 1rem;
  margin: 0 auto 0;
}

.presentation__illustration-container {
  display: flex;
  justify-content: center;
  flex: 0.2 1;
  pointer-events: none;
}

.presentation__presentateur {
  align-self: flex-end;
  max-width: 50%;
  object-fit: contain;
}
.presentation__smartphone {
  max-width: 67%;
  object-fit: contain;
}

.presentation__content {
  flex: 0.8 1;
  padding: 0 0 0 5rem;
  text-align: center;
  pointer-events: none;
}

.presentation__content > h1 {
  color: #3bb9e0;
  font-size: 3rem;
  font-weight: 850;
}
.presentation__content > p {
  font-size: 2rem;
  font-weight: bold;
  white-space: pre-wrap;
  line-height: 40px;
  padding: 2rem 0 0 2rem;
  position: relative;
}

.presentation__content > p::before {
  position: absolute;
  content: "";
  background-image: url(/static/media/quote-left.2cda652c.svg);
  top: 10px;
  left: 18px;
  width: 22px;
  height: 22px;
  opacity: 0.25;
}
.presentation__content > p::after {
  position: absolute;
  content: "";
  background-image: url(/static/media/quote-right.48f83192.svg);
  bottom: 0px;
  right: 0px;
  width: 22px;
  height: 22px;
  opacity: 0.25;
}
.presentation__content > h2 {
  /* text-align: right; */
  font-size: 1.3rem;
  margin-top: 20px;
  font-weight: 600;
}

@media (max-width: 1250px) {
  .presentation__content > p::before {
    top: 0px;
    left: 0px;
  }
}
@media (max-width: 1130px) {
  .presentation__content > h1 {
    font-size: 2.5rem;
  }
  .presentation__content > p {
    font-size: 1.5rem;
    padding: 1rem 0 0 1rem;
  }
  .presentation__content > h2 {
    font-size: 1.1rem;
  }
}
@media (max-width: 985px) {
  .presentation__content > h1 {
    font-size: 2rem;
  }
  .presentation__content > p {
    font-size: 1.3rem;
  }
}
@media (max-width: 850px) {
  .presentation {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .presentation__content > h1 {
    position: relative;
    padding-bottom: 12px;
  }

  .presentation__content > h2 {
    margin-bottom: 20px;
    font-weight: 600;
  }

  .presentation__content > h1::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 30%;
    height: 5px;
    background: #3bb9e0;
    border-radius: 10px;
  }

  .presentation__content {
    flex: 0.8 1;
    padding: 0;
    text-align: center;
  }
  .presentation__presentateur {
    max-width: 30%;
  }
  .presentation__smartphone {
    max-width: 50%;
  }
}

@media (max-width: 590px) {
  .presentation__content > h1::before {
    left: 50%;
    width: 30%;
    height: 10px;
    transform: translateX(-50%);
  }

  .presentation {
    padding-bottom: 15px;
  }

  .presentation__presentateur {
    max-width: 25%;
  }
  .presentation__smartphone {
    max-width: 40%;
  }
}

@media (max-width: 590px) {
  .presentation__content > p::after {
    bottom: -15px;
  }
}

.about {
  /* background-color: #3c3c3c; */
  position: relative;
  overflow: hidden;
}

.about > img {
  position: absolute;
  width: 260px;
  bottom: 0px;
  right: 0px;
  z-index: -1;
  /* opacity: 0.9; */
}

.about__container {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 80%;
  margin: auto;
  min-height: 50vh;
}

.about__container-heading {
  color: #28bbec;
  padding-bottom: 12px;
  margin-bottom: 30px;
  position: relative;
  letter-spacing: 4px;
  font-size: 2.3rem;
  font-weight: 850;
}
.about__container-heading::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50;
  width: 20%;
  height: 8px;
  background: #ebebec;
  border-radius: 10px;
}

.about__container-markus {
  font-weight: 700 !important;
  color: #3bb4d9;
}

.about__container-subheading {
  font-weight: 700;
  font-size: 1.4rem;
  color: rgb(14, 13, 13);
  color: #0a2540;
  margin-bottom: 25px;
}
.about__container-text {
  color: #425466;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

@media (max-width: 850px) {
  .about__container-heading {
    text-align: center;
  }
  .about__container-heading::before {
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    background: #3bb9e0;
  }
}

@media (max-width: 768px) {
  .about__container {
    width: 100%;
    padding: 2rem 1.2rem;
  }
  .about > img {
    opacity: 0.5;
  }

  .about__container-heading {
    font-size: 2rem;
  }
}

@media (max-width: 568px) {
  .about__container-subheading {
    font-size: 1.2rem;
  }
}

.politique * {
  margin: 0;
  padding: 0;
}

.politique {
  padding: 1rem 0;
  max-width: 1000px;
  width: 100%;
  margin: auto;
}

.politique__header h1 {
  text-align: center;
  margin: 1rem 0 1.5rem;
  position: relative;
  padding-bottom: 12px;
  color: #3bb9e0;
  font-size: 2.3rem;
  font-weight: 750;
  letter-spacing: 1px;
}

.politique__header h1::before {
  margin: auto;
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 25%;
  height: 8px;
  background: #ebebec;
  border-radius: 10px;
}

.politique__header p {
  margin-bottom: 16px;
  font-size: 1.2rem;
  color: #0a2540;
  font-weight: 550;
}

.politique__box h2 {
  margin: 1.5rem 0 1rem;
  font-size: 2rem;
  color: #3bb9e0;
  font-weight: 600;
}

.politique__box-content {
  padding: 0 15px;
}

.politique__box-content p {
  /* margin-bottom: 8px; */
  font-size: 1.2rem;
}

.politique__markus {
  font-weight: 800 !important;
  color: #3bb4d9;
}

.politique__box-content-box {
  margin-top: 1.3rem;
  /* padding-left: 15px; */
}

.politique__box-content-box h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.politique__box-content-box p {
  margin-bottom: 0px;
}
.politique__box-content-box p a:hover {
  color: #3bb4d9;
  font-weight: 800;
}

@media (max-width: 1000px) {
  .politique {
    padding: 1rem 1rem;
  }

  .politique__header h1 {
    font-size: 1.8rem;
  }

  .politique__header p {
    font-size: 1.1rem;
  }

  .politique__box-content p {
    font-size: 1.1rem;
  }
}

