.fonctionnalites__wrapper {
  background-color: #3c3c3c;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgb(88, 88, 88);
}

.fonctionnalites__bgImage {
  position: absolute;
  width: 250px;
  bottom: 0;
}

.fonctionnalites {
  width: 95%;
  max-width: 1400px;
  /* height: 100vh; */
  padding: 3rem 0 3rem;
  margin: 0 auto 0;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.fonctionnalites__container {
  /* display: grid;
  grid-template-columns: 3fr 2fr;
  place-items: center; */

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* flex-direction: column; */

  /* height: 100%; */
  /* place-content: center; */
}

.fonctionnalites__content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fonctionnalites__heading {
  color: #fff;
  /* text-align: center; */
  white-space: pre-wrap;
  font-size: 2.3rem;
  font-weight: 600;
  margin: 0 0 0;
}

.fonctionnalites__heading:last-of-type {
  margin-bottom: 1rem;
}

.fonctionnalites__subHeading {
  color: #90d9f1;
  /* text-align: center; */
  white-space: pre-wrap;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 2rem;
}

.fonctionnalites__smartphone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 0.35;
}

.fonctionnalites__smartphone img {
  max-width: 60%;
  object-fit: contain;
}

.fonctionnalites__content-container {
  flex: 0.75;
}

.fonctionnalites__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fonctionnalites__nom {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  white-space: pre-wrap;
  color: #fff;
  margin-bottom: 1.2rem;
}

.fonctionnalites__icon {
  color: #2098d1 !important;
}

@media (max-width: 1000px) {
  .fonctionnalites__heading {
    font-size: 2rem;
  }

  .fonctionnalites__subHeading {
    font-size: 1.2rem;
  }
  .fonctionnalites__nom {
    font-size: 1.3rem;
  }

  .fonctionnalites__bgImage {
    opacity: 0.5;
  }
}

@media (max-width: 800px) {
  .fonctionnalites__container {
    grid-template-columns: 1fr;
    /* grid-template-rows: 1fr 1fr; */
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
  }
  .fonctionnalites__bgImage {
    display: none;
    visibility: hidden;
  }

  .fonctionnalites__content {
    align-items: center !important;
  }
  .fonctionnalites__content > div {
    align-items: flex-start !important;
  }
  .fonctionnalites__nom {
    flex-direction: row !important;
    text-align: left !important;
  }

  .fonctionnalites__icon {
    margin-left: 0 !important;
    margin-right: 1.5rem;
  }

  .fonctionnalites__heading {
    text-align: center;
  }

  .fonctionnalites__subHeading {
    text-align: center;
  }

  .fonctionnalites__smartphone img {
    /* display: none; */
    max-width: 25% !important;
    margin: 0 !important;
  }
}

@media (max-width: 668px) {
  .fonctionnalites {
    width: 100%;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

@media (max-width: 550px) {
  .fonctionnalites__heading {
    font-size: 1.8rem;
  }

  .fonctionnalites__subHeading {
    font-size: 1.1rem;
  }

  .fonctionnalites__nom {
    font-size: 1.1rem;
  }
}
@media (max-width: 500px) {
  .fonctionnalites__smartphone {
    /* display: none; */
    /* grid-row: 0/1; */
  }
  .fonctionnalites__content {
    /* grid-row: 1/2; */
  }
  .fonctionnalites__smartphone img {
    /* display: none; */
  }

  .fonctionnalites__heading {
    font-size: 1.4rem;
  }

  .fonctionnalites__smartphone img {
    max-width: 38% !important;
    object-fit: contain;
    justify-self: center;
    margin: 1rem auto 0 auto;
  }
}
