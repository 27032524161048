.home {
  background-color: #3c3c3c;
}

.home__container {
  width: 95%;
  height: 93vh;
  max-width: 1600px;
  margin: auto;
}

/* .carousel__item {
  background-color: #3c3c3c;
} */

.home__hero {
  display: grid;
  height: 90vh;
  padding: 0 3rem;
  grid-template-columns: 3fr 2fr;
  /* grid-template-rows: repeat(2, 1fr); */
  place-items: center;
}

.home__hero-hadings {
  text-align: left;
  /* grid-column: 1/2;
  grid-row: 1/3;
  align-self: center; */
}

.home__hero-image {
  /* grid-column: 2/3;
  grid-row: 1/3; */
  /* align-self: center; */
  /* align-self: flex-end; */
  pointer-events: none;
}

.home__image {
  pointer-events: none;
  margin-left: 5px;
}

.home__hero-hadings > h1 {
  color: #3bb9e0;
  text-transform: uppercase;
  font-weight: bolder !important;
  font-size: 1.7rem;
  margin-bottom: 10px;
  letter-spacing: 2px;
  /* line-height: 2rem; */
}
.home__hero-hadings > p {
  color: #fff;
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
  line-height: 3.5rem;
}

.home__subtext {
  color: #90d9f1 !important;
  font-size: 1.8rem !important;
  margin-bottom: 2rem !important;
  line-height: 2.3rem !important;
}

.home__hero-image .home__image {
  max-width: 95%;
  object-fit: contain;
}

.telecharger_btn {
  text-decoration: none !important;
  color: #fff !important;
  padding: 0.5rem 3rem;
  border: 1px solid white;
  border-radius: 9px;
  font-size: 1.3rem;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.telecharger_btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 9px;
  background: #2098d1 !important;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.telecharger_btn:hover:before,
.telecharger_btn:focus:before,
.telecharger_btn:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.thumbs-wrapper {
  display: none;
}

.carousel.carousel-slider .control-arrow:hover {
  background: transparent !important;
}

/* Arrow */
.carousel.carousel-slider .control-arrow:before {
  margin: 0 10px;
  display: inline-block;
  border-top: 15px solid transparent !important;
  border-bottom: 15px solid transparent !important;
  content: "";
}

.carousel .control-prev.control-arrow:before {
  border-right: 15px solid #fff !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 15px solid #fff !important;
}

/* indicator */
.carousel-status {
  display: none;
}

.dot {
  width: 15px !important;
  height: 15px !important;
}

.carousel-control-next,
.carousel-control-prev {
  width: 5% !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 32px !important;
  height: 32px !important;
}

@media (max-width: 1100px) {
  .home__hero-hadings > p {
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 950px) {
  .home__hero-hadings > p {
    line-height: 3rem;
  }
}

@media (max-width: 768px) {
  .home__hero-hadings > h1 {
    font-size: 1.5rem;
  }
  .home__hero-hadings > p {
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .home__subtext {
    font-size: 1.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .home__hero {
    grid-template-columns: 1fr;
    padding: 0;
    position: relative;
    z-index: 3;
  }

  .home__hero-image {
    position: absolute;
    top: 55%;
    left: 70%;
    right: 0%;
    transform: translate(-50%, 10%);
    z-index: -1;
    opacity: 0.32;
  }

  .home__hero-image .home__image {
    width: 100%;
    object-fit: contain;
    transform: scale(2);
  }
}
@media (max-width: 583px) {
  .home__hero-hadings > h1 {
    font-size: 1.5rem;
  }
  .home__hero-hadings > p {
    font-weight: bold;
    font-size: 1.7rem;
    line-height: 2.7rem;
  }

  .home__subtext {
    font-size: 1.3rem !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 8% !important;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 25px !important;
    height: 25px !important;
  }
}

@media (max-width: 550px) {
  .home__hero-image {
    position: absolute;
    /* display: none; */
    top: 47%;
    right: 18%;
    transform: translate(0%, 80%);
    z-index: -1;
    opacity: 0.3;
  }

  .home__hero-image .home__image {
    width: 100%;
    object-fit: contain;
    transform: scale(6);
  }
  .home__hero-hadings > h1 {
    font-size: 1.3rem;
  }
  .home__hero-hadings > p {
    font-weight: bold;
    font-size: 1.8rem;
  }

  .home__subtext {
    font-size: 1.4rem !important;
  }

  .telecharger_btn {
    display: block;
    font-size: 1.2rem;
    padding-left: 0;
    padding-right: 0;
    width: 60%;
    text-align: center;
    margin: auto;
  }

  /* .home__hero-image {
    display: none;
  }

  .home__hero-image .home__image {
    display: none;
  } */
}
@media (max-width: 400px) {
  .telecharger_btn {
    font-size: 1.1rem;
    width: 90% !important;
  }
}
@media (max-width: 370px) {
  .home__hero-hadings > h1 {
    font-size: 1rem;
  }
  .home__hero-hadings > p {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 1.5rem !important;
  }

  .home__subtext {
    font-size: 1.3rem !important;
    margin-bottom: 2rem !important;
  }
}
/* Carousel bootstrap style */

/* .carousel-control-next,
.carousel-control-prev {
  width: 5%;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 32px;
  height: 32px;
} */

/* .carousel {
  position: relative;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  width: 5%;
}
[role="button"] {
  cursor: pointer;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
    width: 40px ;
  height: 40px ;
  background: no-repeat 50%/100% 100%;
}


.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
} */
